<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="brand-text">メールを送信しました</h2>
        <h4 class="sub-brand-text">
          パスワード再設定用のメールを（{{$store.state.app.mailSent}}）宛にお送りいたしました。
          ご確認の上メールに記載のあるURLからパスワードを再設定してください。
        </h4>
        <div class="img-fly">
          <img
            src="../../assets/images/pages/confirm-reset-img.png"
            alt="fly"
          />
        </div>
        <h4 class="sub-brand-text">
          ※しばらく経ってもメールが届かない場合は以下をご確認ください
          <br />
          ・メールアドレスに間違いがないか
          <br />
          ・受信拒否設定をしていないか
          <br />
          ・迷惑メールフォルダに入っていないか
        </h4>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard,
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
.brand-text {
  font-family: Noto Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
}

.sub-brand-text {
  font-family: Noto Sans;
  font-size: 17px;
  font-weight: 500;
}

.img-fly {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
</style>
